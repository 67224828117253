import { SMARTER_SEARCH_CLUSTERS, type ZoneFeatures } from 'src/config/types';
import { isATargetDevice } from 'src/modules/app-install-banner';

import { toggleExperiment } from '../experiments/experimentHelpers';
import type { TSeekExperiments } from '../experiments/types';
import type { TypedAction, TypedThunkAction } from '../types';

import {
  UPDATE_FEATURE_FLAGS,
  type FeatureFlagsState,
  type TFeatureFlagNames,
} from './types';

export const updateFeatureFlags =
  (
    cookies: Record<string, string> = {},
    experiments: TSeekExperiments,
    {
      SHOW_FLOATING_SAVE_SEARCH,
      AUTO_SELECT_SPLIT_VIEW_FIRST_JOB,
      SHOW_MARKETING_AD_SPLIT_VIEW,
      BEHAVIOURAL_CUES_ENABLED,
      BEHAVIOURAL_CUES_FILTERS_ENABLED,
      LOGGED_OUT_RECS,
      REMOTE_SEARCH_FILTER,
      REMOTE_SEARCH_FILTER_NEW_LABEL,
      DYNAMIC_PILLS,
      SECONDARY_FILTERS,
      ENABLE_V5_JOB_SEARCH,
      KEYWORD_AUTOSUGGEST_V2,
      NEW_JOB_CARD_DENSITY,
      ENABLE_VERIFIED_HIRER_BADGE,
      SERP_JOBCARD_INFO_DENSITY_1,
      MATCHED_QUALITIES,
    }: ZoneFeatures,
  ): TypedThunkAction =>
  (dispatch) => {
    const secondaryFilters =
      Boolean(cookies.SECONDARY_FILTERS || SECONDARY_FILTERS) ||
      toggleExperiment({
        experimentId: 'secondaryFilters',
        experiments,
        cookieValue: cookies.SECONDARY_FILTERS,
      });

    // Extra safe guard to ensure that dynamic pills can only be enabled if secondary filters is enabled.
    // You cannot enable dynamic pills if secondary filters is disabled.
    const dynamicPills =
      secondaryFilters &&
      (Boolean(cookies.DYNAMIC_PILLS || DYNAMIC_PILLS) ||
        toggleExperiment({
          experimentId: 'dynamicPills',
          experiments,
          cookieValue: cookies.DYNAMIC_PILLS,
        }));

    dispatch({
      type: UPDATE_FEATURE_FLAGS,
      payload: {
        dynamicFlags: {
          behaviouralCues: Boolean(
            cookies.BEHAVIOURAL_CUES_ENABLED || BEHAVIOURAL_CUES_ENABLED,
          ),
          behaviouralCuesFilters:
            Boolean(
              cookies.BEHAVIOURAL_CUES_FILTERS_ENABLED ||
                BEHAVIOURAL_CUES_FILTERS_ENABLED,
            ) ||
            toggleExperiment({
              experimentId: 'behaviouralCuesFilters',
              experiments,
              cookieValue: cookies.BEHAVIOURAL_CUES_FILTERS_ENABLED,
            }),
          branchBannerPreview: Boolean(cookies.BRANCH_BANNER_PREVIEW),
          isBranchEnabledFlag:
            Boolean(cookies.ENABLE_BRANCH_BANNER) || isATargetDevice(),
          retireDisplayAds: cookies.RETIRE_DISPLAY_ADS,
          smarterSearchLaunchCluster: cookies.SMARTER_SEARCH_LAUNCH_CLUSTER,
          showFloatingSaveSearch: Boolean(
            cookies.SHOW_FLOATING_SAVE_SEARCH || SHOW_FLOATING_SAVE_SEARCH,
          ),
          autoSelectSplitViewFirstJob:
            Boolean(cookies.AUTO_SELECT_SPLIT_VIEW_FIRST_JOB) ||
            AUTO_SELECT_SPLIT_VIEW_FIRST_JOB,
          showMarketingAdSplitView: Boolean(
            cookies.SHOW_MARKETING_AD_SPLIT_VIEW ||
              SHOW_MARKETING_AD_SPLIT_VIEW,
          ),
          loggedOutRecs: Boolean(cookies.LOGGED_OUT_RECS || LOGGED_OUT_RECS),
          remoteSearchFilter:
            Boolean(cookies.REMOTE_SEARCH_FILTER || REMOTE_SEARCH_FILTER) ||
            toggleExperiment({
              experimentId: 'remoteSearchFilter',
              experiments,
              cookieValue: cookies.REMOTE_SEARCH_FILTER,
            }),
          remoteSearchFilterNewLabel: Boolean(REMOTE_SEARCH_FILTER_NEW_LABEL),
          dynamicPills,
          secondaryFilters,
          v5JobSearch: Boolean(
            cookies.ENABLE_V5_JOB_SEARCH || ENABLE_V5_JOB_SEARCH,
          ),
          keywordAutosuggestV2: Boolean(
            cookies.KEYWORD_AUTOSUGGEST_V2 || KEYWORD_AUTOSUGGEST_V2,
          ),
          hirerVerifiedBadge: Boolean(
            cookies.ENABLE_VERIFIED_HIRER_BADGE || ENABLE_VERIFIED_HIRER_BADGE,
          ),
          serpJobCardInfoDensity1: Boolean(
            cookies.SERP_JOBCARD_INFO_DENSITY_1 ||
              SERP_JOBCARD_INFO_DENSITY_1 ||
              toggleExperiment({
                experimentId: 'serpJobCardInfoDensity1',
                experiments,
                cookieValue: cookies.SERP_JOBCARD_INFO_DENSITY_1,
              }),
          ),
          newJobCardDensity: Boolean(
            cookies.NEW_JOB_CARD_DENSITY || NEW_JOB_CARD_DENSITY,
          ),
          hideCompanyLogo: Boolean(cookies.HIDE_COMPANY_LOGO),
          homepageJdvDrawer: Boolean(cookies.HOMEPAGE_JDV_DRAWER),
          homepageLayoutRefresh: Boolean(
            cookies.HOMEPAGE_LAYOUT_REFRESH ||
              toggleExperiment({
                experimentId: 'homepageLayoutRefresh',
                experiments,
                cookieValue: cookies.HOMEPAGE_LAYOUT_REFRESH,
              }),
          ),
          matchedQualities: Boolean(
            cookies.MATCHED_QUALITIES ||
              MATCHED_QUALITIES ||
              toggleExperiment({
                experimentId:
                  'talentFitSignal_skillsMatch' as TFeatureFlagNames,
                experiments,
                cookieValue: cookies.MATCHED_QUALITIES,
              }),
          ),
          loggedInGroupSegmentTest: Boolean(
            cookies.LOGGED_IN_GROUP_SEGMENT_TEST ||
              toggleExperiment({
                experimentId: 'loggedInGroupSegmentTest',
                experiments,
                cookieValue: cookies.LOGGED_IN_GROUP_SEGMENT_TEST,
              }),
          ),
        },
      },
    });
  };

export const initialState: FeatureFlagsState = {
  behaviouralCues: false,
  behaviouralCuesFilters: false,
  branchBannerPreview: false,
  isBranchEnabledFlag: false,
  retireDisplayAds: false,
  smarterSearchLaunchCluster: SMARTER_SEARCH_CLUSTERS[0],
  showHomepageBanner: false,
  showFloatingSaveSearch: true,
  autoSelectSplitViewFirstJob: true,
  showMarketingAdSplitView: false,
  loggedOutRecs: false,
  remoteSearchFilter: false,
  remoteSearchFilterNewLabel: false,
  dynamicPills: false,
  secondaryFilters: false,
  v5JobSearch: false,
  keywordAutosuggestV2: false,
  hirerVerifiedBadge: false,
  serpJobCardInfoDensity1: false,
  newJobCardDensity: false,
  hideCompanyLogo: false,
  homepageJdvDrawer: false,
  homepageLayoutRefresh: false,
  matchedQualities: false,
  loggedInGroupSegmentTest: false,
};

export default function reducer(
  state = initialState,
  action: TypedAction,
): FeatureFlagsState {
  switch (action.type) {
    case UPDATE_FEATURE_FLAGS: {
      const { dynamicFlags } = action.payload;
      return {
        ...state,
        ...dynamicFlags,
      };
    }

    default:
      return state;
  }
}

export const AUTO_SELECT_SPLIT_VIEW_FIRST_JOB = 'autoSelectSplitViewFirstJob';
export const SHOW_FLOATING_SAVE_SEARCH_FEATURE: TFeatureFlagNames =
  'showFloatingSaveSearch';

export const SHOW_MARKETING_AD_SPLIT_VIEW_FEATURE: TFeatureFlagNames =
  'showMarketingAdSplitView';

export const LOGGED_OUT_RECS: TFeatureFlagNames = 'loggedOutRecs';

export const SERP_JOBCARD_INFO_DENSITY_1: TFeatureFlagNames =
  'serpJobCardInfoDensity1';

export const HOMEPAGE_LAYOUT_REFRESH: TFeatureFlagNames =
  'homepageLayoutRefresh';
